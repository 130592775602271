.page_title {
	padding: 60px 15px 15px;
	text-transform: uppercase;
	background-color: $brand-primary-dark;
	color: white;

	/* .page-title {
		margin: 0; 
		font-weight: $heading-weight-bold;
	} */
	@include media-breakpoint-up(md) {
		padding-top: 120px;
	}
	@include media-breakpoint-up(lg) {
		padding-top: 120px;
	}
}
