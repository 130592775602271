.page_welcome {
	padding: 30px 15px 0;

	+.page_content { padding-top: 15px; }

	@include media-breakpoint-up(lg) {
		padding: 15px;
		.container {
			padding: 15px;
			background-image: linear-gradient(
				to right,
				transparent 49.92%,
				#cccccc 49.92%,
				#cccccc 50.08%,
				transparent 50.08%
			);
		}
		.row {
			align-items: center; 
			justify-content: center;
		}
		.welcome_lead { @include make-col(50,100); }
		.welcome_more { @include make-col(50,100); padding-left: 30px; }
	}

}

.page_content {
	padding: 30px 15px;

	.wrap > :first-child { margin-top: 0; }
	.wrap > :last-child { margin-bottom: 0; }

	.sidebar { margin-top: 30px; }

	@include media-breakpoint-up(lg) {
		.main { @include make-col(61,100); }
		.sidebar {
			@include make-col(39,100); 
			margin-top: 0;
		}
	}
}

.page_gallery .page_content {
	@include media-breakpoint-up(lg) {
		.main { @include make-col(100, 160); }
		.sidebar { @include make-col(60, 160); }
	}
	@include media-breakpoint-up(xl) {
		.main { @include make-col(110, 160); }
		.sidebar { @include make-col(50, 160); }
	}
}

.page_quote .page_content {
	@include media-breakpoint-up(lg) {
		.main { @include make-col(60, 160); }
		.sidebar { @include make-col(100, 160); }
	}
} 

.page_contact {
	
	.page_contact_form { 
		padding: 30px;
		background-color: $brand-highlight; 
		color: white;
	}

	@include media-breakpoint-up(lg) {
		margin: 30px 0;
		padding: 0;
		background-image: linear-gradient(
			to right,
			$brand-highlight 50%,
			transparent 50%
		);

		.row { 
			align-items: center;
			justify-content: space-between; 
		}
		.page_contact_form { @include make-col(4.85,10); }
		.page_contact_sidebar {
			@include make-col(4.85,10); 
			margin-top: 0;
		}
	}
}


.content_wrapper {
	+.content_wrapper { margin-top: 30px; }
	@include media-breakpoint-up(lg) {
		&:nth-child(even) {
			padding: 30px 0;
			border-top: 1px solid #ccc;
			border-bottom: 1px solid #ccc;
			background-color: rgba(#ddd,0.4); 
			backdrop-filter: blur(5px);
			.sidebar { order: 1; }
			.main { order: 2; }
		}
	}
}