.sidebar {
	.form {
		margin-bottom: 15px;
		padding: 20px;
		@include font-size(16);
		line-height: 1.275em;
		border-radius: 8px;
		border: 1px solid #ccc;
		background-color: white;
	}
	.form_flex-clear {
		margin-top: 10px;
 	}
} 