//.main {}


.contact_block_flex {

	@include media-breakpoint-up(sm) {
		display: flex;
		flex-wrap: nowrap;
		> div:first-child { margin-right: 30px; }
	}

	@include media-breakpoint-only(lg) {
		line-height: 1.2;
		.h3 {
			@include font-size(20);
		}
	}

}


.page_resources_wrap {
	padding: 30px;
	.container { padding: 0 15px; }
	.wrap+.wrap { margin-top: 30px; }
	@include media-breakpoint-up(md) {
		.row { justify-content: space-between; }
		.wrap+.wrap { margin-top: 0; }
	}
}


.resource_item {
	
	padding: 30px;
	border-radius: 12px;
	background-color: white;
	text-align: center;

	h2 {
		padding-bottom: 30px;
		border-bottom: 1px solid #dddddd;
	}

	@include media-breakpoint-up(md) {
		@include make-col(6,13);
	}

}