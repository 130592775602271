hr {
	display: block;
	width: 100%;
	height: 1px;
	margin: 30px auto;
	border: none;
	background-color: #bbb;

	&.hr-inset {
		border: none;
		background-color: #bbb;
		box-shadow: 0 1px 0 0 #fff;
	}

}