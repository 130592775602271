.gallery-wrapper {
	/* @include make-flex;
	flex-wrap: wrap;
	@include media-breakpoint-only(xs) {
		justify-content: space-between;
	} */

	.gallery-item {
		display: inline-block;
		padding: 5px;
	}

}


	.gallery-item a {
		display: inline-block;
		border: 3px solid white;
		overflow: hidden;
		line-height: 0;
		transition: border-color 300ms;
		picture, img {
			transition: transform 300ms;
		}
		&:hover {
			transition: border-color 300ms;
			border-color: $color-hover;
			picture, img {
				transition: transform 300ms;
				transform: scale(1.1);
			}
		}
	}


/* 	.gallery-item {
		width: (100 - (2*2)) / 2 * 1%;
		margin-bottom: 1%;
		picture, img {
			display: block;
			height: auto;
			transition: transform 300ms;
			width: auto;
			max-width: 100%;
		}
		a {
			display: inline-block;
			border: 3px solid white;
			overflow: hidden;
			transition: border-color 300ms;
			&:hover {
				transition: border-color 300ms;
				border-color: $color-hover;
				picture, img {
					transition: transform 300ms;
					transform: scale(1.1);
				}
			}
		}
		@include media-breakpoint-up(sm) {
			width: (100 - (2*3)) / 3 * 1%;
			&:not(:nth-child(3n)) { margin-right: 2%; }
		}
	} */