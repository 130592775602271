blockquote {
	margin: 0 0 $global_mb;
	padding: 20px;
	font-size: 107%;
	line-height: $base-line-height;
	border-left: 5px solid $color-text;
	
	.title {
		@include font-size(21);
		font-family: $font-family-headings;
	}
	
	> *:first-child { margin-top: 0; }
	> *:last-child { margin-bottom: 0; }
}

